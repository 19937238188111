import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {createCompound} from "../actions/compounds";
import Papa from "papaparse";
import {FiUploadCloud, FiFilePlus, FiCheckCircle} from "react-icons/fi";

const BulkAddCompound = () => {
	const [bulkCsv, setBulkCsv] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const dispatch = useDispatch();

	const handleCsvUpload = (event) => {
		const file = event.target.files[0];
		if (!file) {
			setErrorMessage("Please select a CSV file");
			return;
		}

		if (!file.name.endsWith(".csv")) {
			setErrorMessage("Only CSV files are allowed");
			return;
		}

		Papa.parse(file, {
			header: true,
			dynamicTyping: true,
			complete: (results) => {
				setBulkCsv(results.data.filter(row =>
					Object.values(row).some(val => val !== null && val !== undefined)
				));
				setErrorMessage("");
			},
			error: (error) => {
				console.error("CSV parse error:", error);
				setErrorMessage("Error parsing CSV file");
			}
		});
	};

	const addRows = () => {
		const tableBody = document.querySelector("#main-table tbody");
		tableBody.innerHTML = "";

		bulkCsv.forEach((compound, index) => {
			const row = document.createElement("tr");
			row.className = "upload-row";
			row.innerHTML = `
        <td className="align-middle">${compound["Compound Index"] || ''}</td>
        <td className="align-middle">${compound["Compound Material"] || ''}</td>
        <td className="align-middle">${compound["Compound Notation"] || ''}</td>
        <td>
          <div className="custom-file">
            <input type="file" 
                   className="custom-file-input" 
                   id="mol2_${index}" 
                   accept=".mol2, .txt"
                   data-testid="mol2-${index}">
            <label className="custom-file-label" htmlFor="mol2_${index}">Choose MOL2</label>
          </div>
        </td>
        <td>
          <div className="custom-file">
            <input type="file"
                   className="custom-file-input"
                   id="components_${index}"
                   accept=".csv, .txt"
                   data-testid="components-${index}">
            <label className="custom-file-label" htmlFor="components_${index}">Choose CSV</label>
          </div>
        </td>
        <td>
          <div className="custom-file">
            <input type="file"
                   className="custom-file-input"
                   id="properties_${index}"
                   accept=".csv, .txt"
                   data-testid="properties-${index}">
            <label className="custom-file-label" htmlFor="properties_${index}">Choose CSV</label>
          </div>
        </td>
      `;
			tableBody.appendChild(row);
		});

		// Add file input change handlers
		document.querySelectorAll('.custom-file-input').forEach(input => {
			input.addEventListener('change', function (e) {
				const fileName = e.target.files[0]?.name || 'Choose file';
				const label = this.nextElementSibling;
				label.textContent = fileName;
				label.classList.add('text-truncate');
			});
		});
	};

	// Components CSV Handler
	const handleComponentsUpload = (rowNum) => {
		return new Promise((resolve, reject) => {
			const fileInput = document.getElementById(`components_${rowNum}`);
			if (!fileInput.files.length) {
				resolve("[]"); // Empty array as fallback
				return;
			}

			Papa.parse(fileInput.files[0], {
				header: true,
				dynamicTyping: true,
				complete: (results) => {
					const cleaned = results.data.filter(row =>
						Object.values(row).some(val => val !== null && val !== undefined)
					);
					resolve(cleaned); // Send as JS object

				},
				error: (error) => reject(`Components CSV error: ${error}`)
			});
		});
	};

	// Properties CSV Handler
	const handlePropertiesUpload = (rowNum) => {
		return new Promise((resolve, reject) => {
			const fileInput = document.getElementById(`properties_${rowNum}`);
			if (!fileInput.files.length) {
				resolve("[]"); // Empty array as fallback
				return;
			}

			Papa.parse(fileInput.files[0], {
				header: true,
				dynamicTyping: true,
				complete: (results) => {
					const cleaned = results.data.filter(row =>
						Object.values(row).some(val => val !== null && val !== undefined)
					);
					resolve(cleaned); // Send as JS object
				},
				error: (error) => reject(`Properties CSV error: ${error}`)
			});
		});
	};

	// MOL2 File Handler
	const handleMolUpload = (rowNum) => {
		return new Promise((resolve, reject) => {
			const fileInput = document.getElementById(`mol2_${rowNum}`);
			if (!fileInput.files.length) {
				reject("No MOL2 file uploaded");
				return;
			}

			const reader = new FileReader();
			reader.onload = () => {
				try {
					const sections = reader.result.split("@<TRIPOS>").filter(s => s.trim());
					let mol2JSON = {mol2: {}};

					sections.forEach(section => {
						const lines = section.split("\n").filter(l => l.trim());
						const sectionName = lines[0].trim();
						const data = lines.slice(1);

						switch (sectionName) {
							case "MOLECULE":
								mol2JSON.mol2.molecule = parseMolecule(data);
								break;
							case "ATOM":
								mol2JSON.mol2.atom = parseAtoms(data);
								break;
							case "BOND":
								mol2JSON.mol2.bond = parseBonds(data);
								break;
							case "SUBSTRUCTURE":
								mol2JSON.mol2.substructure = parseSubstructure(data);
								break;
							default:
								console.warn(`Unknown section: ${sectionName}`);
						}
					});

					resolve(JSON.stringify(mol2JSON));
				} catch (error) {
					reject(`MOL2 parse error: ${error}`);
				}
			};

			reader.onerror = () => reject("Error reading MOL2 file");
			reader.readAsText(fileInput.files[0]);
		});
	};


	// Parsing functions (same as single component)
	const parseMolecule = (data) => ({
		mol_name: data[0]?.trim() || "",
		num_atoms: data[1]?.split(/\s+/)[0] || "",
		num_bonds: data[1]?.split(/\s+/)[1] || "",
		num_subst: data[1]?.split(/\s+/)[2] || "",
		num_feat: data[1]?.split(/\s+/)[3] || "",
		num_sets: data[1]?.split(/\s+/)[4] || "",
		mol_type: data[2]?.trim() || "",
		charge_type: data[3]?.trim() || "",
		status_bits: data[4]?.trim() || "",
		mol_comment: data[5]?.trim() || ""
	});

	const parseAtoms = (data) => data.map(line => {
		const parts = line.trim().split(/\s+/);
		return {
			atom_id: parts[0] || "",
			atom_name: parts[1] || "",
			x: parts[2] || "",
			y: parts[3] || "",
			z: parts[4] || "",
			atom_type: parts[5] || "",
			subst_id: parts[6] || "",
			subst_name: parts[7] || "",
			charge: parts[8] || ""
		};
	});

	const parseBonds = (data) => data.map(line => {
		const parts = line.trim().split(/\s+/);
		return {
			bond_id: parts[0] || "",
			origin_atom_id: parts[1] || "",
			target_atom_id: parts[2] || "",
			bond_type: parts[3] || ""
		};
	});

	const parseSubstructure = (data) => data.map(line => line.trim());


	// Main upload handler with loading state
	const uploadCompounds = async () => {
		if (!bulkCsv.length) {
			setErrorMessage("Please upload a CSV file first");
			return;
		}

		setIsLoading(true);
		setErrorMessage("");

		try {
			const results = await Promise.allSettled(
				bulkCsv.map(async (compound, index) => {
					const [mol2, components, properties] = await Promise.all([
						handleMolUpload(index),
						handleComponentsUpload(index),
						handlePropertiesUpload(index)
					]);

					return await dispatch(createCompound(
						compound["Compound Index"],
						compound["Compound Material"],
						compound["Compound Notation"],
						mol2,
						components,
						properties
					));
				})
			);

			const failures = results.filter(r => r.status === "rejected");
			if (failures.length) {
				const errorList = failures.map(f => f.reason).join('\n');
				setErrorMessage(`${failures.length} compounds failed to upload:\n${errorList}`);
			} else {
				setSubmitted(true);
				setTimeout(() => setSubmitted(false), 5000);
			}
		} catch (error) {
			console.error("Bulk upload error:", error);
			setErrorMessage(`Upload failed: ${error.message}`);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="container mt-4">
			<div className="card shadow-sm">
				<div className="card-header bg-primary text-white">
					<h3 className="mb-0">
						<FiUploadCloud className="mr-2"/>
						Bulk Compound Upload
					</h3>
				</div>

				<div className="card-body" style={{maxHeight: '50vh', overflowY: 'auto'}}>
					<div className="form-group">
						<label className="font-weight-bold">Step 1: Upload Compound CSV</label>
						<div className="custom-file">
							<input
								type="file"
								className="custom-file-input"
								id="bulkCsv"
								accept=".csv"
								onChange={handleCsvUpload}
							/>
							<label className="custom-file-label" htmlFor="bulkCsv">
								Choose CSV file...
							</label>
						</div>
						<small className="form-text text-muted">
							CSV should contain columns: Compound Index, Compound Material, Compound Notation
						</small>
					</div>

					<button
						className="btn btn-outline-primary mt-3"
						onClick={addRows}
						disabled={!bulkCsv.length}
					>
						<FiFilePlus className="mr-2"/>
						{bulkCsv.length ? `Preview ${bulkCsv.length} Compounds` : "Preview Compounds"}
					</button>

					{bulkCsv.length > 0 && (
						<>
							<div className="table-responsive mt-4">
								<table id="main-table" className="table table-hover table-striped">
									<thead className="thead-dark">
									<tr>
										<th style={{width: '10%'}}>Index</th>
										<th style={{width: '20%'}}>Material</th>
										<th style={{width: '15%'}}>Notation</th>
										<th style={{width: '20%'}}>MOL2 File</th>
										<th style={{width: '20%'}}>Components CSV</th>
										<th style={{width: '20%'}}>Properties CSV</th>
									</tr>
									</thead>
									<tbody className="bg-white"></tbody>
								</table>
							</div>

							<div className="mt-4 d-flex justify-content-between align-items-center">
								<button
									className="btn btn-success px-5"
									onClick={uploadCompounds}
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											<span className="spinner-border spinner-border-sm mr-2" role="status"/>
											Uploading...
										</>
									) : (
										<>
											<FiUploadCloud className="mr-2"/>
											Upload All Compounds
										</>
									)}
								</button>

								<span className="text-muted">
                  {bulkCsv.length} compounds ready for upload
                </span>
							</div>
						</>
					)}

					{errorMessage && (
						<div className="alert alert-danger mt-3 fade show">
							<strong>Error:</strong> {errorMessage}
						</div>
					)}

					{submitted && (
						<div className="alert alert-success mt-3 fade show">
							<FiCheckCircle className="mr-2"/>
							<strong>Success!</strong> All compounds uploaded successfully
						</div>
					)}
				</div>
			</div>

			<style jsx>{`
				.custom-file-label::after {
					content: "Browse";
				}

				.upload-row td {
					vertical-align: middle !important;
				}
 
				.table-hover tbody tr:hover {
					background-color: rgba(0, 123, 255, 0.05);
				}

				.card {
					border-radius: 0.8rem;
				}
			`}</style>
		</div>
	);
};



export default BulkAddCompound;