import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { MDBContainer, MDBIcon } from "mdbreact";
import './App.css';
import Compound from "./components/Compound";
import CompoundList from "./components/CompoundList";
import AddCompound from "./components/AddCompound";
import BulkAddCompound from "./components/BulkAddCompound";
import NewCompoundsPage from "./components/NewCompoundsPage";


function App() {
	return (
		<Router>
			<div className="d-flex flex-column min-vh-100">

				{/* Navigation Header */}
				<nav className="navbar navbar-expand-lg navbar-light bg-light border mb-4">
					<div className="container-fluid">
						<div className="navbar-brand me-2 fw-bold fs-3">
							<Link to={"/"}>Materials Base</Link>
						</div>

						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item fw-bolder">
									<Link to={"/"}>Homepage</Link>
								</li>
								<li className="breadcrumb-item fw-bolder">
									<Link to={"/add"}>Add Compound</Link>
								</li>
								<li className="breadcrumb-item fw-bolder">
									<Link to={"/bulkAdd"}>Bulk Add Compound</Link>
								</li>
								<li className="breadcrumb-item fw-bolder">
									<Link to={"/new-compounds"}>View Compounds</Link>
								</li>
							</ol>
						</nav>
					</div>
				</nav>

				{/* Main Content */}
				<main className="flex-grow-1 mb-5">
					<MDBContainer fluid className="py-4 px-5">
						<Routes>
							<Route exact path="/" element={<CompoundList />} />
							<Route exact path="/add" element={<AddCompound />} />
							<Route exact path="/bulkAdd" element={<BulkAddCompound />} />
							<Route path="/:comp_id" element={<Compound />} />
							<Route exact path="/new-compounds" element={<NewCompoundsPage />} />
						</Routes>
					</MDBContainer>
				</main>

				{/* Footer */}
				<footer className="text-center text-light fixed-bottom" style={{backgroundColor:"#f1f1f1"}}>
					<div className="container pt-1">
						<section>
							<a className="btn btn-link btn-floating btn-lg text-dark m-1"
							   href="https://github.com/bakhras/MaterialsBase"
							   role="button"
							   data-mdb-ripple-color="dark"
							>
								<img alt="github" src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width="30px" height="30px"/>
							</a>
						</section>
					</div>
					<div className="text-center text-dark p-3" style={{backgroundColor:"rgba(0,0,0,0.2)"}}>
						MaterialsBase | North Dakota State University 2022
					</div>
				</footer>

			</div>
		</Router>
	);
}

export default App;
