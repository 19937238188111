import axios from "axios";
// baseURL: "http://localhost:8080/api",

export default axios.create({
	baseURL: "https://backend.nanobiodata.org/api",
	//baseURL: "http://localhost:8080/api",
	headers: {
		"Content-type": "application/json"
	}
});
