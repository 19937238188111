import React, { useState, useEffect, dispatch } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	retrieveCompounds,
	findCompoundById,
	findCompoundByIndex,
	deleteAllCompounds,
} from "../actions/compounds";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';

const CompoundsList = () => {
	const [currentCompound, setCurrentCompound] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [searchTitle, setSearchTitle] = useState("");
	const compounds = useSelector(state => state.compounds);
	const dispatch = useDispatch();
	const [viewMode, setViewMode] = useState(false);

	useEffect(() => {
		dispatch(retrieveCompounds());
	}, []);

	const onChangeSearchTitle = e => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};

	const refreshData = () => {
		setCurrentCompound(null);
		setCurrentIndex(-1);
	};

	const setActiveCompound = (compound, index) => {
		setCurrentCompound(compound);
		setCurrentIndex(index);
	};

	const removeAllCompounds = () => {
		dispatch(deleteAllCompounds())
			.then(response => {
				console.log(response);
				refreshData();
			})
			.catch(err => {
				console.log(err);
			});
	};

	const findByTitle = () => {
		refreshData();
		dispatch(findCompoundByIndex(searchTitle));
	};

	const downloadMol2 = () => {
		const element = JSONtoMOL2();
		const url = window.URL.createObjectURL(new Blob([element]));
		const link = document.createElement('a');
		const filename = currentCompound.comp_material + ".mol2";

		link.href = url;
		link.setAttribute(
			'download',
			filename
		);

		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	};


	const JSONtoMOL2 = () => {
		const obj = JSON.parse(currentCompound.comp_mol2);

		// Start building the MOL2 file content
		let mol2FileString = "@<TRIPOS>MOLECULE\n";

		// MOLECULE section
		mol2FileString += `${obj.mol2.molecule.mol_name}\n`;
		mol2FileString += `${obj.mol2.molecule.num_atoms} ${obj.mol2.molecule.num_bonds} ${obj.mol2.molecule.num_subst} ${obj.mol2.molecule.num_feat} ${obj.mol2.molecule.num_sets}\n`;
		mol2FileString += `${obj.mol2.molecule.mol_type}\n`;
		mol2FileString += `${obj.mol2.molecule.charge_type}\n\n`;

		// ATOM section
		mol2FileString += "@<TRIPOS>ATOM\n";
		obj.mol2.atom.forEach(atom => {
			mol2FileString += `${atom.atom_id.toString().padStart(7)} ${atom.atom_name.padEnd(5)} ${parseFloat(atom.x).toFixed(4).padStart(10)} ${parseFloat(atom.y).toFixed(4).padStart(10)} ${parseFloat(atom.z).toFixed(4).padStart(10)} ${atom.atom_type.padEnd(8)} ${atom.subst_id.toString().padStart(3)} ${atom.subst_name.padEnd(8)} ${parseFloat(atom.charge).toFixed(4).padStart(8)}\n`;
		});

		// BOND section
		mol2FileString += "\n@<TRIPOS>BOND\n";
		obj.mol2.bond.forEach(bond => {
			mol2FileString += `${bond.bond_id.toString().padStart(5)} ${bond.origin_atom_id.toString().padStart(5)} ${bond.target_atom_id.toString().padStart(5)} ${bond.bond_type.padStart(5)}\n`;
		});

		// SUBSTRUCTURE section
		mol2FileString += "\n@<TRIPOS>SUBSTRUCTURE\n";
		obj.mol2.substructure.forEach(sub => {
			mol2FileString += `${sub}\n`;
		});

		console.log(mol2FileString);
		return mol2FileString.trim();
	};

// comment

	const formatSpacing = (spacingMap) => {
		var largestStringLength = 0;
		spacingMap.forEach((value, key) => {
			const JSONEntryMap = new Map(Object.entries(value));
			JSONEntryMap.forEach((value, key) => {
				largestStringLength = Math.max(largestStringLength, value.length)
			})

		})
		return largestStringLength + 1;
	}



	const downloadCSV = () => {
		try {
			let csvData = currentCompound.comp_properties;

			// Check if comp_properties is a JSON object
			if (typeof csvData === "object") {
				// Convert JSON to CSV
				const convertJSONToCSV = (jsonData) => {
					const rows = [];
					const keys = Object.keys(jsonData[0]); // Extract column headers from the first row
					rows.push(keys.join(',')); // Create the header row

					jsonData.forEach((row) => {
						const values = keys.map((key) => row[key]); // Map values for each row
						rows.push(values.join(','));
					});

					return rows.join('\n');
				};


				// Convert JSON object to CSV string
				csvData = convertJSONToCSV(csvData);
			} else if (typeof csvData === "string") {
				// If already a string, assume it's a valid CSV or JSON string
				console.warn("comp_properties is already a string. Ensure it's in CSV format.");
			} else {
				throw new Error("comp_properties is neither JSON object nor a string.");
			}

			// Create the CSV file and download it
			const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			const filename = `${currentCompound.comp_material}.csv`;

			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading CSV:", error);
		}
	};

	const downloadComponents = () => {
		try {
			let csvData = currentCompound.comp_components;

			// Check if comp_components is a JSON object
			if (typeof csvData === "object") {
				// Convert JSON to CSV
				const convertJSONToCSV = (jsonData) => {
					const rows = [];
					const keys = Object.keys(jsonData[0]); // Extract column headers from the first row
					rows.push(keys.join(",")); // Create the header row

					jsonData.forEach((row) => {
						const values = keys.map((key) => row[key]); // Map values for each row
						rows.push(values.join(","));
					});

					return rows.join("\n");
				};

				// Convert JSON object to CSV string
				csvData = convertJSONToCSV(csvData);
			} else if (typeof csvData === "string") {
				// If already a string, assume it's a valid CSV or JSON string
				console.warn("comp_components is already a string. Ensure it's in CSV format.");
			} else {
				throw new Error("comp_components is neither JSON object nor a string.");
			}

			// Create the CSV file and download it
			const blob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			const filename = `${currentCompound.comp_material}_components.csv`;

			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Error downloading components:", error);
		}
	};


	const filteredCompound = compounds.filter(compounds => {
		return compounds.comp_material.toLowerCase().includes(searchTitle.toLowerCase())
	});

	const handlepropertiesView = () => {
		setViewMode("properties");
	}
	const handlecomponentsView = () => {
		setViewMode("components");
	}

	return (
		<div className="container-fluid vh-200 d-flex flex-column p-0" style={{backgroundColor: '#f8f9fa'}}>
			<div className="row g-0 flex-grow-1">
				{/* Search and List Section */}
				<div className="col-xl-4 h-100 d-flex flex-column">
					<div className="card shadow-sm border-0 flex-grow-1 m-3 d-flex flex-column">
						<div className="card-header py-3" style={{
							backgroundColor: '#13baed',
							color: 'white',
							borderRadius: '2px solid #0f9dc5'
						}}>
							<h4 className="mb-0"><i className="bi bi-database me-2"></i>Compounds Library</h4>
							<div className="input-group mt-3">
								<input
									type="text"
									className="form-control border-0 py-2"
									placeholder="Search compounds..."
									value={searchTitle}
									onChange={onChangeSearchTitle}
									style={{fontSize: '1.1rem'}}
								/>
								<button
									className="btn py-2"
									type="button"
									style={{
										backgroundColor: '#0f9dc5',
										color: 'white',
										borderRadius: '0 10px 10px 0',
										width: '60px'
									}}
								>
									<i className="bi bi-search fs-5"></i>
								</button>
							</div>
						</div>
						<div className="card-body p-0 flex-grow-1 " style={{overflow: 'auto'}}>
							<div className="list-group list-group-flush " style={{
								maxHeight: '70vh',
								overflowY: 'auto',
								fontSize: '1.05rem'
							}}>
								{filteredCompound.map((compound, index) => (
									<button
										type="button"
										className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center py-3 ${index === currentIndex ? 'active' : ''}`}
										onClick={() => setActiveCompound(compound, index)}
										key={compound.comp_id}
										style={{
											borderLeft: index === currentIndex ? '4px solid #13baed ' : '4px solid transparent',
											transition: 'all 0.2s ease'
										}}
									>
										<span className="fw-medium">{compound.comp_material}</span>
										<span className="badge py-2" style={{
											backgroundColor: index === currentIndex ? 'white' : '#9CD3D9',
											color: index === currentIndex ? '#13baed' : 'darkslategrey',
											fontSize: '0.9rem',
											minWidth: '70px'
										}}>
                                            #{compound.comp_index}
                                        </span>
									</button>
								))}
							</div>
						</div>
						<div className="card-footer py-3 bg-white border-top">
							<button
								className="btn w-100 py-2"
								// onClick={removeAllCompounds}
								style={{
									backgroundColor: '#13baed20',
									color: 'black',
									borderRadius: '10px',
									fontSize: '1.1rem'
								}}
							>
								<i className="bi bi-trash me-2"></i>Clear All Compounds
							</button>
						</div>
					</div>
				</div>

				{/* Compound Details Section */}
				<div className="col-xl-4 ">
					{currentCompound ? (
						<div className="card shadow-sm border-0 h-100 w-100">
							<div className="card-header py-3" style={{
								backgroundColor: '#13baed',
								color: 'white',
								borderBottom: '2px solid #0f9dc5'
							}}>
								<h4 className="mb-0"><i className="bi bi-info-circle me-2"></i>Compound Details</h4>
							</div>
							<div className="card-body d-flex flex-column" style={{
								height: 'calc(120vh - 220px)',
								overflowY: 'auto',
								paddingBottom: '10px'
							}}>
								<dl className="row mb-4">
									<dt className="col-sm-4 text-muted fs-5">PubChem ID</dt>
									<dd className="col-sm-8 fw-bold fs-5" style={{color: '#3f51b5'}}>
										{currentCompound.comp_index}
									</dd>

									<dt className="col-sm-4 text-muted fs-5">Material</dt>
									<dd className="col-sm-8 fs-5" style={{color: '#673ab7'}}>
										{currentCompound.comp_material}
									</dd>

									<dt className="col-sm-4 text-muted fs-5">Notation</dt>
									<dd className="col-sm-8 fs-5">{currentCompound.comp_notation}</dd>
								</dl>

								<div className="d-grid gap-3">
									<div className="btn-group-vertical gap-3">
										<button
											className="btn text-start py-3"
											onClick={handlepropertiesView}
											style={{
												border: '2px solid #13baed',
												color: 'darkslategrey',
												fontSize: '1.1rem'
											}}
										>
											<i className="bi bi-table me-3"></i>View Properties
										</button>
										<button
											className="btn text-start py-3"
											onClick={handlecomponentsView}
											style={{
												border: '2px solid #13baed',
												color: 'darkslategrey',
												fontSize: '1.1rem'
											}}
										>
											<i className="bi bi-diagram-3 me-3"></i>View Components
										</button>
									</div>

									<div className="mt-4">
										<h5 className="mb-3" style={{color: '#13baed'}}>
											<i className="bi bi-download me-2"></i>Export Data
										</h5>
										<div className="d-grid gap-2">
											<button
												className="btn text-start py-2"
												onClick={downloadMol2}
												style={{
													backgroundColor: '#13BAED20',
													color: 'darkslategrey',
													border: '2px solid #13BAED20',
													fontSize: '1rem'
												}}
											>
												<i className="bi bi-file-earmark-arrow-down me-2"></i>
												Download MOL2 File
											</button>
											<button
												className="btn text-start py-2"
												onClick={downloadCSV}
												style={{
													backgroundColor: '#13BAED20',
													color: 'darkslategrey',
													border: '2px solid #13baed20',
													fontSize: '1rem'
												}}
											>
												<i className="bi bi-file-earmark-spreadsheet me-2"></i>
												Export Properties CSV
											</button>
											<button
												className="btn text-start py-2"
												onClick={downloadComponents}
												style={{
													backgroundColor: '#13baed20',
													color: 'darkslategrey',
													fontSize: '1rem'
												}}
											>
												<i className="bi bi-file-earmark-zip me-2"></i>
												Export Components CSV
											</button>

											{/* Edit Button placed right after Export buttons */}
											<div className="mt-4">
												<Link
													to={`/${currentCompound.comp_id}`}
													className="btn w-100 py-3"
													style={{
														backgroundColor: '#13baed20',
														color: '#673ab7',
														fontSize: '1.1rem',
														boxShadow: '0 -2px 10px rgba(0,0,0,0.3)',
													}}
												>
													<i className="bi bi-pencil-square me-2"></i>Edit Compound Details
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					) : (
						<div className="card shadow-sm border-0 h-100">
							<div className="card-header py-3" style={{backgroundColor: '#13baed', color: 'white'}}>
								<h5 className="mb-0"><i className="bi bi-question-circle me-2"></i>Quick Guide</h5>
							</div>
							<div className="card-body">
								<div className="alert mb-0" style={{
									backgroundColor: '#ede7f6',
									color: 'seagreen'
								}}>
									<i className="bi bi-info-circle me-2"></i>
									Select a compound from the list to view detailed information.
									Use the search bar to filter compounds by name. Click "Edit"
									to modify compound details or use the download buttons to
									export data.
								</div>
							</div>
						</div>
					)}
				</div>

				{/* Data Table Section */}
				<div className="col-xl-4 px-2 h-100 d-flex flex-column">
					{viewMode && (
						<div className="card shadow-sm border-0 flex-grow-1 m-3 d-flex flex-column">
							<div className="card-header py-3" style={{
								backgroundColor: '#13baed',
								color: 'white',
								borderBottom: '2px solid #0f9dc5'
							}}>
								<h4 className="mb-0">
									<i className={`bi ${viewMode === 'properties' ? 'bi-table' : 'bi-collection'} me-2`}></i>
									{viewMode === 'properties' ? 'Material Properties' : 'Chemical Components'}
								</h4>
							</div>
							<div className="card-body p-0 flex-grow-1">
								<div style={{

									overflowY: 'auto'
								}}>
									<table className="table align-middle mb-0">
										<thead className="sticky-top" style={{
											backgroundColor: '#e3f2fd',
											color: '#0f9dc5',
											fontSize: '1.1rem'
										}}>
										<tr>
											{viewMode === 'properties' ? (
												<>
													<th style={{width: '35%'}}>Property</th>
													<th style={{width: '40%'}}>Value</th>
													<th style={{width: '25%'}}>Unit</th>
												</>
											) : (
												<>
													<th style={{width: '40%'}}>Component</th>
													<th style={{width: '30%'}}>Quantity</th>
													<th style={{width: '30%'}}>Unit</th>
												</>
											)}
										</tr>
										</thead>
										<tbody>
										{Object.entries(
											viewMode === 'properties'
												? currentCompound.comp_properties
												: currentCompound.comp_components
										).map(([key, value], i) => (
											<tr key={i} className="py-3">
												<td className="fw-medium" style={{color: '#3f51b5', padding: '1rem'}}>
													{value[viewMode === 'properties' ? 'Property' : 'ComponentName'] || 'N/A'}
												</td>
												<td style={{padding: '1rem'}}>
													{value[viewMode === 'properties' ? 'Value' : 'Quantity'] || 'N/A'}
												</td>
												<td style={{padding: '1rem'}}>
                                                    <span className="badge  py-2 px-3" style={{
														backgroundColor: '#9CD3D9',
														color: 'darkslategrey',
														fontSize: '0.95rem'
													}}>
                                                        {value.Unit || 'N/A'}
                                                    </span>
												</td>
											</tr>
										))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CompoundsList;
