import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateCompound, deleteCompound } from "../actions/compounds";
import CompoundDataService from "../services/compound.service";

const Compound = () => {
    const initialCompoundState = {
        comp_index: "",
        comp_material: "",
        comp_notation: "",
        comp_mol2: "",
        comp_components: null,
        comp_properties: null,
    };

    const { comp_id } = useParams();
    const navigate = useNavigate();
    const [currentCompound, setCurrentCompound] = useState(initialCompoundState);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const getCompound = useCallback(() => {
        CompoundDataService.get(comp_id)
            .then((response) => {
                setCurrentCompound(response.data);
            })
            .catch((err) => {
                console.error("Error fetching compound:", err);
                setMessage("Compound not found or deleted. Redirecting...");
                setTimeout(() => navigate("/"), 2000);
            });
    }, [comp_id, navigate]);

    useEffect(() => {
        getCompound();
    }, [getCompound]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCurrentCompound({ ...currentCompound, [name]: value });
    };

    const updateContent = (event) => {
        event.preventDefault(); // ✅ Prevent unwanted form submission

        if (!currentCompound.comp_id) {
            console.error("Error: Compound ID is missing.");
            setMessage("Error: Unable to update. Compound ID is missing.");
            return;
        }

        dispatch(updateCompound(currentCompound.comp_id, currentCompound))
            .then((response) => {
                console.log("Update Response:", response);
                setMessage("The compound was updated successfully.");
                setTimeout(() => navigate("/"), 2000);
            })
            .catch((err) => {
                console.error("Error updating compound:", err);
                setMessage("Failed to update compound. Please try again.");
            });
    };


    const removeCompound = (event) => {
        event.preventDefault(); // ✅ Prevent form submission

        dispatch(deleteCompound(currentCompound.comp_id))
            .then(() => {
                setMessage("Compound deleted successfully. Redirecting...");
                setTimeout(() => navigate("/"), 2000);
            })
            .catch((err) => {
                console.error("Error deleting compound:", err);
                setError("Failed to delete compound. Please try again.");
            });
    };

    return (
        <div className="container mt-5">
            {currentCompound ? (
                <div className="card shadow-lg p-4">
                    <h2 className="text-center text-primary">Edit Compound</h2>
                    <form>
                        <div className="form-group">
                            <label className="font-weight-bold">Compound Index</label>
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                name="comp_index"
                                value={currentCompound.comp_index}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">Compound Material</label>
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                name="comp_material"
                                value={currentCompound.comp_material}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">Compound Notation</label>
                            <input
                                type="text"
                                className="form-control shadow-sm"
                                name="comp_notation"
                                value={currentCompound.comp_notation}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {error && <div className="alert alert-danger text-center">{error}</div>}
                        {message && <div className="alert alert-success text-center">{message}</div>}

                        <div className="text-center mt-4">
                            <button className="btn btn-danger mx-2" onClick={removeCompound}>
                                <i className="fas fa-trash"></i> Delete
                            </button>
                            <button className="btn btn-success" onClick={updateContent}>
                                <i className="fas fa-save"></i> Update
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="text-center mt-5">
                    <h4 className="text-secondary">{message || "Loading compound data..."}</h4>
                </div>
            )}
        </div>
    );
};

export default Compound;
