import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createCompound } from "../actions/compounds";
import Papa from "papaparse";
import { Link } from "react-router-dom";

const AddCompound = () => {
	// ✅ Define state variables
	const [compound, setCompound] = useState({
		comp_index: "",
		comp_material: "",
		comp_notation: "",
		comp_mol2: "",
		comp_components: "",
		comp_properties: "",
	});

	const [submitted, setSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const dispatch = useDispatch();

	// ✅ Reset form for "Add More"
	const resetForm = () => {
		setCompound({
			comp_index: "",
			comp_material: "",
			comp_notation: "",
			comp_mol2: "",
			comp_components: "",
			comp_properties: "",
		});
		setSubmitted(false);
		setErrorMessage("");
	};

	// ✅ Handle input changes
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setCompound((prevState) => ({ ...prevState, [name]: value }));
	};

	// ✅ Handle CSV Uploads
	const handleCsvUpload = (event) => {
		if (!event.target || !event.target.files || event.target.files.length === 0) {
			console.warn("No file selected for upload.");
			return;
		}

		const { name } = event.target;
		const file = event.target.files[0];

		Papa.parse(file, {
			header: true,
			dynamicTyping: true,
			complete: (results) => {
				setCompound((prevState) => ({
					...prevState,
					[name]: results.data.filter(row => Object.values(row).some(val => val !== null && val !== "")),
				}));
			},
			error: (error) => {
				setErrorMessage(`CSV parse error: ${error.message}`);
			}
		});
	};

	// ✅ Used your strict MOL2 Parsing Logic
	const handleMol2Upload = (event) => {
		const { name } = event.target;
		const reader = new FileReader();

		reader.readAsText(event.target.files[0]);

		reader.onload = () => {
			try {
				console.log("Raw file content:", reader.result);

				// Split the .mol2 content into sections
				const sections = reader.result
					.split("@<TRIPOS>")
					.filter((section) => section.trim().length > 0);

				console.log("Split sections:", sections);

				let mol2JSON = { mol2: {} };
				sections.forEach((section) => {
					const lines = section.split("\n").filter((line) => line.trim().length > 0);
					const sectionName = lines[0].trim();
					const data = lines.slice(1);

					switch (sectionName) {
						case "MOLECULE":
							mol2JSON.mol2.molecule = parseMolecule(data);
							break;
						case "ATOM":
							mol2JSON.mol2.atom = parseAtoms(data);
							break;
						case "BOND":
							mol2JSON.mol2.bond = parseBonds(data);
							break;
						case "SUBSTRUCTURE":
							mol2JSON.mol2.substructure = parseSubstructure(data);
							break;
						default:
							console.warn(`Unknown section: ${sectionName}`);
					}
				});

				console.log("Generated JSON:", mol2JSON);
				setCompound({ ...compound, [name]: JSON.stringify(mol2JSON) });
			} catch (error) {
				console.error("Error parsing MOL2 file:", error);
			}
		};
	};

	const parseMolecule = (data) => ({
		mol_name: data[0]?.trim() || "",
		num_atoms: data[1]?.split(/\s+/)[0] || "",
		num_bonds: data[1]?.split(/\s+/)[1] || "",
		num_subst: data[1]?.split(/\s+/)[2] || "",
		num_feat: data[1]?.split(/\s+/)[3] || "",
		num_sets: data[1]?.split(/\s+/)[4] || "",
		mol_type: data[2]?.trim() || "",
		charge_type: data[3]?.trim() || "",
		status_bits: data[4]?.trim() || "",
		mol_comment: data[5]?.trim() || "",
	});

	const parseAtoms = (data) => data.map(line => {
		const parts = line.trim().split(/\s+/);
		return {
			atom_id: parts[0] || "",
			atom_name: parts[1] || "",
			x: parts[2] || "",
			y: parts[3] || "",
			z: parts[4] || "",
			atom_type: parts[5] || "",
			subst_id: parts[6] || "",
			subst_name: parts[7] || "",
			charge: parts[8] || "",
		};
	});

	const parseBonds = (data) => data.map(line => {
		const parts = line.trim().split(/\s+/);
		return {
			bond_id: parts[0] || "",
			origin_atom_id: parts[1] || "",
			target_atom_id: parts[2] || "",
			bond_type: parts[3] || "",
		};
	});

	const parseSubstructure = (data) => data.map(line => line.trim());

	// ✅ Save Compound
	const saveCompound = async () => {
		console.log("saveCompound() Clicked");
		setIsLoading(true);
		setErrorMessage("");

		try {
			const {
				comp_index,
				comp_material,
				comp_notation,
				comp_mol2,
				comp_components,
				comp_properties
			} = compound;

			const data = await dispatch(createCompound(
				comp_index,
				comp_material,
				comp_notation,
				comp_mol2,
				comp_components,
				comp_properties
			));

			console.log("createCompound(): ", data);
			setSubmitted(true);

		} catch (error) {
			console.error("createCompound error:", error);
			setErrorMessage("Failed to submit compound. Try again.");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="container-fluid mt-4">
			{/* ✅ Progress Bar (Visible when uploading) */}
			{isLoading && (
				<div className="progress fixed-top" style={{ height: "10px", zIndex: 1000 }}>
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						style={{ width: "100%", backgroundColor: "#28a745" }}
					></div>
				</div>
			)}

			<div className="row">
				{/* ✅ Left Sidebar (Instructions) */}
				<div className="col-md-3">
					<div className="card shadow-sm">
						<div className="card-header bg-dark text-white">
							<h5>📌 Instructions</h5>
						</div>
						<div className="card-body">
							<ul>
								<li><b>Compound Index:</b> A unique numeric identifier.</li>
								<li><b>Compound Material:</b> Name of the material.</li>
								<li><b>Compound Notation:</b> Chemical formula.</li>
								<li><b>MOL2 File:</b> Must be a `.mol2` file.</li>
								<li><b>Components CSV:</b> Should include molecular components.</li>
								<li><b>Properties CSV:</b> Must list computed or experimental properties.</li>
							</ul>
						</div>
					</div>
				</div>

				{/* ✅ Right Section (Upload Form) */}
				<div className="col-md-9">
					<div className="card shadow-lg">
						<h5 className="card-header bg-dark text-white">📤 Upload Compound Data</h5>
						<div className="card-body">
							{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

							{submitted ? (
								// ✅ Success State
								<div className="text-center py-5">
									<h2 className="text-success mb-4">
										<i className="fas fa-check-circle"></i> Compound Added Successfully!
									</h2>
									<div className="d-flex justify-content-center gap-3">
										<button
											onClick={resetForm}
											className="btn btn-outline-primary btn-lg"
										>
											<i className="fas fa-plus-circle"></i> Add Another Compound
										</button>
										<Link to="/" className="btn btn-primary btn-lg">
											<i className="fas fa-home"></i> Return to Homepage
										</Link>
									</div>
								</div>
							) : (
								// ✅ Existing Form
								<form>
									<div className="form-group">
										<label className="font-weight-bold">Compound Index</label>
										<input
											type="text"
											className="form-control shadow-sm"
											name="comp_index"
											value={compound.comp_index}
											onChange={handleInputChange}
											required
										/>
									</div>

									<div className="form-group">
										<label className="font-weight-bold">Compound Material</label>
										<input
											type="text"
											className="form-control shadow-sm"
											name="comp_material"
											value={compound.comp_material}
											onChange={handleInputChange}
											required
										/>
									</div>

									<div className="form-group">
										<label className="font-weight-bold">Compound Notation</label>
										<input
											type="text"
											className="form-control shadow-sm"
											name="comp_notation"
											value={compound.comp_notation}
											onChange={handleInputChange}
											required
										/>
									</div>

									{/* File Uploads */}
									<div className="form-group">
										<label className="font-weight-bold d-block">MOL2 File</label>
										<input
											type="file"
											accept=".mol2"
											className="form-control-file shadow-sm"
											name="comp_mol2"
											onChange={handleMol2Upload}
											required
										/>
									</div>

									<div className="form-group">
										<label className="font-weight-bold d-block">Components CSV</label>
										<input
											type="file"
											accept=".csv"
											className="form-control-file shadow-sm"
											name="comp_components"
											onChange={handleCsvUpload}
											required
										/>
									</div>

									<div className="form-group">
										<label className="font-weight-bold d-block">Properties CSV</label>
										<input
											type="file"
											accept=".csv"
											className="form-control-file shadow-sm"
											name="comp_properties"
											onChange={handleCsvUpload}
											required
										/>
									</div>

									<button
										onClick={saveCompound}
										className="btn btn-success btn-lg w-100 shadow-sm mt-3"
										disabled={isLoading}
									>
										{isLoading ? (
											<>
                        <span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
												Uploading...
											</>
										) : (
											"Submit Compound"
										)}
									</button>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCompound;