import React, { useEffect, useState } from "react";
import { Table, Button, Input, Upload, message } from "antd";
import { UploadOutlined, DownloadOutlined , DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { saveAs } from "file-saver";
import {  Popconfirm} from "antd";

import { Modal, Form } from "antd"; // Importing Modal and Form
import { EditOutlined } from "@ant-design/icons"; // Importing Edit icon




const { Search } = Input;
const { confirm } = Modal;

const NewCompoundsPage = () => {
    const [compounds, setCompounds] = useState([]);
    const [filteredCompounds, setFilteredCompounds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedCompound, setSelectedCompound] = useState(null);
    const [editForm] = Form.useForm(); // Form instance for editing

    const isLocalhost = window.location.hostname === "localhost";
    const API_BASE = isLocalhost ? "http://localhost:8080" : "https://backend.nanobiodata.org";

    // comment
    useEffect(() => {
        fetchCompounds();
    }, []);

    const fetchCompounds = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE}/api/new-compo/allnewcompo`);

            const data = await response.json();
            if (data.message.includes("✅")) {
                setCompounds(data.data);
                setFilteredCompounds(data.data);
            } else {
                console.error("Error fetching compounds:", data.error);
            }
        } catch (error) {
            console.error("Error fetching compounds:", error);
        }
        setLoading(false);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = compounds.filter(
            (compound) =>
                compound.compo_name.toLowerCase().includes(query.toLowerCase()) ||
                compound.compo_id.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCompounds(filtered);
    };

    // Open Edit Modal
    const handleEdit = (compound) => {
        setSelectedCompound(compound); // Set selected compound for editing
        editForm.setFieldsValue({
            compo_name: compound.compo_name,
            compo_smiles: compound.compo_smiles,
            properties: compound.properties.map((p) => `${p.name}: ${p.value} ${p.unit || ""}`).join(" | "),
            descriptors: compound.descriptors.map((d) => `${d.name}: ${d.value}`).join(" | "),
            components: compound.components.map((c) => `${c.name}: ${c.quantity}`).join(" | "),
        });
        setEditModalVisible(true);
    };



    const handleSaveEdit = async () => {
        try {
            const updatedValues = await editForm.validateFields();

            console.log("🔹 Raw Edited Values:", updatedValues); // Debug log

            const parseField = (field) => {
                if (!field || typeof field !== "string" || field.trim() === "") {
                    return [];
                }
                return field.split(" | ").map((item) => {
                    const parts = item.split(": ");
                    if (parts.length < 2) return null; // Skip invalid data

                    const [name, valueUnit] = parts;
                    const [value, unit] = valueUnit.split(" ");

                    return { name, value: value || "", unit: unit || "" };
                }).filter(Boolean);
            };

            const updatedCompound = {
                compo_name: updatedValues.compo_name,
                compo_smiles: updatedValues.compo_smiles,
                properties: parseField(updatedValues.properties),
                descriptors: parseField(updatedValues.descriptors),
                components: parseField(updatedValues.components).map((c) => ({
                    name: c.name,
                    quantity: c.value ? parseFloat(c.value) || 0 : 0, // Ensure quantity is a number
                })),
            };

            console.log("🔹 Formatted Payload for API:", updatedCompound); // Debug log

            const response = await fetch(`${API_BASE}/api/new-compo/update-compound/${selectedCompound.compo_id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedCompound),
            });

            // Check response type before parsing
            const contentType = response.headers.get("content-type");
            const responseBody = await response.text();

            console.log("🔹 Raw Server Response:", responseBody); // Debug log

            if (!contentType || !contentType.includes("application/json")) {
                throw new Error("❌ Unexpected response format. Received HTML instead of JSON.");
            }

            const result = JSON.parse(responseBody);
            console.log("🔹 Parsed JSON Response:", result); // Debug log

            if (!response.ok) {
                throw new Error(result.error || "Failed to update compound");
            }

            message.success("✅ Compound updated successfully!");
            setEditModalVisible(false);
            fetchCompounds(); // Refresh data after update

        } catch (error) {
            console.error("❌ Error updating compound:", error);
            message.error("❌ Error updating compound: " + error.message);
        }
    };




    const showDeleteConfirm = (compound) => {
        confirm({
            title: "Are you sure you want to delete this compound?",
            icon: <ExclamationCircleOutlined />,
            content: `Compound: ${compound.compo_name}`,
            onOk: () => handleDelete(compound.compo_id),
        });
    };




    const downloadCSV = () => {
        // Define the correct column order
        const csvHeader = "Compound ID,Compound Name,SMILES,Components,Descriptors,Properties\n";

        const csvRows = compounds.map(compound => {
            // Convert arrays into properly formatted strings
            const properties = compound.properties.length
                ? compound.properties.map(p => `${p.name}: ${p.value} ${p.unit || ''}`).join(" | ")
                : "N/A";

            const descriptors = compound.descriptors.length
                ? compound.descriptors.map(d => `${d.name}: ${d.value}`).join(" | ")
                : "N/A";

            const components = compound.components.length
                ? compound.components.map(c => `${c.name}: ${c.quantity}`).join(" | ")
                : "N/A";

            return `${compound.compo_id},${compound.compo_name},${compound.compo_smiles},"${components}","${descriptors}","${properties}"`;
        });

        // Create a properly formatted CSV string
        const csvString = csvHeader + csvRows.join("\n");

        // Convert to UTF-8 format
        const blob = new Blob(["\ufeff" + csvString], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "compounds_data.csv");
    };



    const handleDelete = async (compo_id) => {
        try {
            console.log(`🔹 Sending DELETE request for compound ID: ${compo_id}`);

            const response = await fetch(`${API_BASE}/api/new-compo/delete-compound/${compo_id}`, {
                method: "DELETE",
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.error || "Failed to delete compound");
            }

            message.success("✅ Compound deleted successfully!");
            fetchCompounds(); // Refresh the list
        } catch (error) {
            console.error("❌ Error deleting compound:", error);
            message.error("❌ Error deleting compound: " + error.message);
        }
    };


    const uploadProps = {
        name: "file",
        accept: ".csv",
        multiple: false,
        showUploadList: false,
        beforeUpload: async (file) => {
            const formData = new FormData();
            formData.append("file", file);

            try {
                const response = await fetch(`${API_BASE}/api/new-compo/bulk-upload-compounds`, {
                    method: "POST",
                    body: formData,
                });

                const result = await response.json();
                if (response.ok) {
                    message.success("✅ CSV uploaded successfully!");
                    fetchCompounds(); // Refresh data after upload
                } else {
                    message.error(`❌ Upload failed: ${result.error}`);
                }
            } catch (error) {
                message.error("❌ Upload error: " + error.message);
            }

            return false; // Prevent default upload behavior
        },
    };



    const columns = [
        {
            title: "Compound ID",
            dataIndex: "compo_id",
            key: "compo_id",
            sorter: (a, b) => a.compo_id.localeCompare(b.compo_id),
        },
        {
            title: "Name",
            dataIndex: "compo_name",
            key: "compo_name",
        },
        {
            title: "SMILES",
            dataIndex: "compo_smiles",
            key: "compo_smiles",
            render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
        },
        {
            title: "Properties",
            dataIndex: "properties",
            key: "properties",
            render: (props) =>
                props && props.length > 0
                    ? props.map((p, index) => (
                        <div key={index}>{`${p.name}: ${p.value} ${p.unit || ""}`}</div>
                    ))
                    : "N/A",
        },
        {
            title: "Descriptors",
            dataIndex: "descriptors",
            key: "descriptors",
            render: (descs) =>
                descs && descs.length > 0
                    ? descs.map((d, index) => <div key={index}>{`${d.name}: ${d.value}`}</div>)
                    : "N/A",
        },
        {
            title: "Components",
            dataIndex: "components",
            key: "components",
            render: (comps) =>
                comps && comps.length > 0
                    ? comps.map((c, index) => <div key={index}>{`${c.name}: ${c.quantity}`}</div>)
                    : "N/A",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <div className="action-buttons">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} className="edit-btn">
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this compound?"
                        onConfirm={() => handleDelete(record.compo_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} danger className="delete-btn">
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div style={{ padding: "20px" }}>
            <h2>📌 New Compounds Table</h2>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <Search
                    placeholder="Search by name or ID"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: "300px" }}
                />
                <div>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>📤 Upload CSV</Button>
                    </Upload>
                    <Button type="primary" onClick={downloadCSV} style={{ marginLeft: "10px" }} icon={<DownloadOutlined />}>
                        📥 Download CSV
                    </Button>
                </div>
            </div>
            <Table
                dataSource={filteredCompounds}
                columns={columns}
                rowKey="compo_id"
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: "max-content" }}
            />

            {/* Edit Modal */}
            <Modal
                title="Edit Compound"
                open={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                onOk={handleSaveEdit}
            >
                <Form form={editForm} layout="vertical">
                    <Form.Item name="compo_name" label="Compound Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="compo_smiles" label="SMILES" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="properties" label="Properties">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="descriptors" label="Descriptors">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="components" label="Components">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default NewCompoundsPage;
